import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { Project } from 'src/app/shared/models/views-models/project.model';
import { ProjectSettings } from 'src/app/shared/models/views-models/projectSettings.model';
import { ProjectStatus } from 'src/app/shared/models/views-models/projectStatus.model';
import { LocalStorageService } from 'src/app/shared/service/views-services/localstorage.service';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { CurrentProjectService } from '../../../service/views-services/current-project.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';
import { ProjectDialogComponent } from '../project-dialog/project-dialog.component';
import { ProjectSettingsComponent } from '../project-settings/project-settings.component';

@Component({
    selector: 'app-project-card',
    templateUrl: './project-card.component.html',
    styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {
    @Input() project: Project;
    @Input() user: any;
    @Output() reloadProjects = new EventEmitter();

    isProjectOn = false;
    emptyLastRunAtStr = ' - - / - - / - - - -  ,  - - : - - : - - ';
    lastRunStr: String;
    projectSettings: ProjectSettings = new ProjectSettings();
    projectStatus: ProjectStatus = new ProjectStatus();
    dialogRef: any;
    dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    projectName: string;
    projectDescription: string;
    projectArea: string;

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
            cancelAvailable: false,
        },
    };

    constructor(
        private projectService: ProjectService,
        private currentProjectService: CurrentProjectService,
        private lsService: LocalStorageService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.getProjectSettingsAndStatus();
        this.setNameAndDescription();
        if (this.project.projectDefault) {
            this.lsService.reset(this.project);
            this.lsService.triggerLocalStorageChanges(this.project);
        }
    }

    getProjectSettingsAndStatus() {
        this.projectService.getProjectSettings(this.project.id).subscribe((response) => {
            this.projectSettings = response;
            this.projectSettings.project = this.project;
        });
        this.getProjectStatus();
    }

    getProjectStatus() {
        this.projectService.getProjectStatus(this.project.id).subscribe((response) => {
            this.projectStatus = response;
            this.lastRunStr = this.projectStatus.lastRunAt
                ? new Date(this.projectStatus.lastRunAt.trim().replace(' ', 'T') + 'Z').toLocaleString(
                      'pt-BR',
                      this.dateOptions
                  )
                : this.emptyLastRunAtStr;
        });
    }

    setNameAndDescription() {
        this.projectName =
            this.project.name.length > 20 ? this.project.name.substring(0, 20) + '...' : this.project.name;
        this.projectDescription =
            this.project.description && this.project.description.length > 180
                ? this.project.description.substring(0, 180) + '...'
                : this.project.description;
        this.projectArea =
            (this.project.area && this.project.area.length) > 10
                ? this.project.area.substring(0, 20) + '...'
                : this.project.area;
    }

    editProject() {
        let conf = {
            component: ProjectDialogComponent,
            panelClass: 'custom-dialog-container',
            data: this.project,
        };
        this.openDialog(conf);
    }

    setProjectDefault() {
        this.project.projectDefault = true;
        this.projectService.setProjectDefault(this.project).subscribe((response) => {
            this.project = response;
            this.lsService.reset(this.project);
            this.lsService.triggerLocalStorageChanges(this.project);
            this.currentProjectService.setCurrentProject(response);
            this.currentProjectService.goHome();
        });
    }

    editProjectSettings() {
        let conf = {
            component: ProjectSettingsComponent,
            panelClass: 'custom-dialog-container',
            data: this.projectSettings,
        };
        this.openDialog(conf);
    }

    deleteProject() {
        let conf = {
            component: DeleteDialogComponent,
            panelClass: 'pop-up-dialog-container',
            maxWidth: '600px',
            data: {
                title: 'Deseja excluir este projeto?',
                message: `Todas as informações cadastradas no projeto ${this.project.name} serão excluídas permanentemente.`,
            },
        };
        this.openDialog(conf);
        this.dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.projectService.deleteProject(this.project.id).subscribe((response) => {
                    if (response) {
                        //this.triggerDelete();
                        this.lsService.clean(this.project);
                        this.defaultDialog.data.message = MessagesEnum.DeleteMessage;
                        this.openDialog(this.defaultDialog);
                        this.reloadProjects.emit(true);
                    }
                });
            }
        });
    }

    openDialog(conf): void {
        this.dialogRef = this.dialog.open(conf.component, {
            width: 'auto',
            height: 'auto',
            panelClass: conf.panelClass,
            maxWidth: conf.maxWidth ? conf.maxWidth : '1200px',
            maxHeight: '800px',
            data: conf.data,
        });
        this.dialogRef.afterClosed().subscribe((response) => {
            if (response && [ProjectDialogComponent, ProjectSettingsComponent].includes(conf.component)) {
                this.getProjectSettingsAndStatus();
                this.reloadProjects.emit(true);
            }
        });
    }

    changeProjectStatus() {
        this.defaultDialog.data.message = MessagesEnum.changeProjectStatus;
        this.defaultDialog.data.cancelAvailable = true;
        this.openDialog(this.defaultDialog);
        this.dialogRef.afterClosed().subscribe((response) => {
            this.defaultDialog.data.cancelAvailable = false;
            if (response) {
                this.projectSettings.projectOnWebapp = !this.projectSettings.projectOnWebapp;
                this.projectService.saveProjectStatus(this.projectSettings).subscribe((response) => {
                    if (response) {
                        this.lsService.triggerLocalStorageChanges(this.project);
                        this.defaultDialog.data.message = MessagesEnum.SuccessMessage;
                        this.openDialog(this.defaultDialog);
                    }
                });
            } else {
                this.reloadProjects.emit(true);
            }
        });
    }

    triggerDelete() {
        this.projectService.deploySettings().subscribe((system) => {});
    }
    
    getCurrentProjectVersionType(): string {
        return this.currentProjectService.getCurrentProject().versionType;
    }
}
